<template>
  <div>
    <FilterPagamentiEconomato
      @getEconomatoList="getEconomatoListResetPage"
      @resetFilters="resetFilters"
    />
    <TablePagamentiEconomato
      @getEconomatoList="getEconomatoList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import FilterPagamentiEconomato from "@/components/components-fit/gestione/economato/FiltriPagamentiEconomato.vue";
import TablePagamentiEconomato from "@/components/components-fit/gestione/economato/TablePagamentiEconomato.vue";

export default defineComponent({
  name: "pagamenti-economato",
  components: {
    FilterPagamentiEconomato,
    TablePagamentiEconomato,
  },
  props: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Economato");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const anno = computed(() => store.getters.stagioneSelected);
    const idComitatoRegionale = computed(
      () => store.getters.idComitatoRegionalePagamenti
    );
    const dataInsDa = computed(() => store.getters.dataInsDaPagamenti);
    const dataInsA = computed(() => store.getters.dataInsAPagamenti);
    const dataApprDa = computed(() => store.getters.dataApprDaPagamenti);
    const dataApprA = computed(() => store.getters.dataApprAPagamenti);
    const tipoPagamento = computed(() => store.getters.tipoPagamentoPagamenti);
    const tipoCredDeb = computed(() => store.getters.tipoCredDebPagamenti);
    const approvato = computed(() => store.getters.approvatoPagamenti);
    const idSocieta = computed(() => store.getters.idSocietaPagamenti);
    const idTesserato = computed(() => store.getters.idTesseratoPagamenti);
    const numeroPagamenti = computed(() => store.getters.numeroPagamenti);
    const meseApprovazione = computed(
      () => store.getters.meseApprovazionePagamenti
    );

    const rowsToSkip = computed(() => store.getters.rowsToSkipPagamenti);
    const fetchRows = computed(() => store.getters.fetchRowsPagamenti);
    const sortColumn = computed(() => store.getters.sortColumnPagamenti);
    const sortOrder = computed(() => store.getters.sortOrderPagamenti);
    const tutteStagioniPagamentiEconomato = computed(
      () => store.getters.tutteStagioniPagamentiEconomato
    );

    const getEconomatoList = () => {
      const localKeys = {
        stagione: tutteStagioniPagamentiEconomato.value ? 10000 : anno.value,
        id_Comitato: idComitatoRegionale.value,
        data_Ins_Da: dataInsDa.value,
        data_Ins_A: dataInsA.value,
        data_Appr_Da: dataApprDa.value,
        data_Appr_A: dataApprA.value,
        tipo_Pagamento: tipoPagamento.value,
        tipocreddeb: tipoCredDeb.value,
        approvato: approvato.value,
        id_Societa: idSocieta.value,
        id_Persona: idTesserato.value,
        numero: numeroPagamenti.value,
        mese: meseApprovazione.value,
        rowstoskip: rowsToSkip.value,
        fetchrows: fetchRows.value,
        sortcolumn: sortColumn.value,
        sortorder: sortOrder.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.ECONOMATO_PAGAMENTI_LIST,
        itemName: "economato_pagamenti_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersPagamenti");
      getEconomatoList();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedeconomato_pagamenti_list")
    );
    const getEconomatoListResetPage = () => {
      if (!loaded.value) return;
      store.commit("resetRowsToSkipPagamenti");
      getEconomatoList();
    };
    getEconomatoList();

    return {
      getEconomatoList,
      resetFilters,
      getEconomatoListResetPage,
      tutteStagioniPagamentiEconomato,
    };
  },
});
</script>

<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div class="modal fade" tabindex="-10" id="kt_modal_data_pagamento">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Approvazione movimento pagamento</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <div class="card-body">
            <div class="ps-5 pe-5 fw-bold row">
              <div class="fw-bold col-4" v-if="pagamentoSelected">
                Data pagamento:
              </div>
              <div class="col-4">
                <button class="bugSolver"></button>
                <Datepicker
                  v-model="data_Competenza"
                  @update:modelValue="
                    $event ? (data_Competenza = fromUTCtoISODate($event)) : ''
                  "
                  placeholder=""
                  :format="format"
                  :monthChangeOnScroll="false"
                  :enableTimePicker="false"
                  inputClassName="h-28"
                  :type="hidden"
                  autoApply
                  :maxDate="new Date()"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeModalApprovaPagamento"
          >
            Chiudi
          </span>
          <span
            type="button"
            class="badge bg-secondary rounded blueFit fs-6"
            @click="apprMovPagamento()"
          >
            Approva
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import { format, fromUTCtoISODate } from "@/composables/formatDate.js";

import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";

import { alertSuccess, alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { approvaMovPagamento } from "@/requests/economatoPagamentiRequests";

import { useFormatDateYMDPersoneList } from "@/composables/formatDate";

export default defineComponent({
  name: "modal-approva-data-pagamento",
  data() {
    return {};
  },
  props: {
    pagamentoSelected: {
      type: Object,
      required: true,
    },
  },
  emits: ["getEconomatoList"],
  components: { Datepicker, Loading },
  setup(props, { emit }) {
    const data_Competenza = ref(null);

    watch(
      () => props.pagamentoSelected,
      (value) => {
        if (value) {
          data_Competenza.value = useFormatDateYMDPersoneList(
            props.pagamentoSelected.data_inserimento
          );
        }
      }
    );

    /*     useFormatDateYMDPersoneList(props.pagamentoSelected.data_inserimento) */

    const isLoading = ref(false);

    const apprMovPagamento = () => {
      if (data_Competenza.value != null) {
        Swal.fire({
          title: "Sei Sicuro?",
          text: "Il movimento verrà approvato.",
          icon: "warning",
          buttonsStyling: false,
          showCancelButton: true,
          cancelButtonText: "Annulla",
          confirmButtonText: "Approva",
          allowOutsideClick: false,
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
            cancelButton: "btn fw-bold btn-light-warning",
          },
        }).then(async (e) => {
          if (e.isDismissed) {
            return;
          }
          if (e.isConfirmed) {
            isLoading.value = true;
            await approvaMovPagamento(
              props.pagamentoSelected.id_movimento,
              props.pagamentoSelected.id_persona,
              props.pagamentoSelected.id_societa,
              props.pagamentoSelected.anno,
              data_Competenza.value
            ).then((res) => {
              if (res.status == 200) {
                alertSuccess(
                  "Movimento approvato correttamente",
                  "kt_modal_data_pagamento"
                );
                emit("getEconomatoList");
                data_Competenza.value = null;
              } else {
                alertFailed(
                  res.data.message
                    ? res.data.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
              }
              document.getElementById("closeModalApprovaPagamento").click();
              isLoading.value = false;
            });
          }
        });
      } else {
        const strMsg = ref("Campo data pagamento obbligatorio");
        Swal.fire({
          html: strMsg.value,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
    };

    return {
      format,
      fromUTCtoISODate,
      apprMovPagamento,
      isLoading,
      data_Competenza,
      useFormatDateYMDPersoneList,
    };
  },
});
</script>

<style scoped>
.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}

.form-check-input[type="radio"] {
  margin-top: 0.15rem !important;
  padding: 0px;
}
</style>

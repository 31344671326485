<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div
          class="pt-4 pb-4 pe-4 ps-4"
          @keyup.enter="$emit('getEconomatoList')"
        >
          <div class="row pb-4">
            <div class="col-sm-4 filter">
              <SelectInput
                placeholder="Comitato"
                :options="comitati"
                :value="idComitatoRegionale"
                name="id_periferico"
                @changeSelect="setComitato"
              />
            </div>
            <div class="col-sm-4 filter">
              <SelectInput
                :options="tipiPagamento"
                name="tipo_pagamento"
                placeholder="Tipo Pagamento"
                :value="tipoPagamento"
                @changeSelect="setTipoPagamento"
              />
            </div>
            <div class="col-sm-4 filter">
              <SelectInput
                placeholder="Creditore / Debitore"
                :options="tipiCredDeb"
                :value="tipoCredDeb"
                name="tipo_ced_deb"
                @changeSelect="setTipoCredDeb"
              />
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-sm-6 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Società"
                  name="nome_societa"
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  @input="
                    inputFocus = 'societa';
                    getSocieta($event.target.value);
                  "
                  :value="nomeSocieta"
                />
                <input
                  name="id_societa"
                  type="hidden"
                  class="form-control"
                  v-model="id_societa"
                />
                <svg
                  @click="setSocieta(null)"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListSocieta
                :focusOnInput="inputFocus === 'societa'"
                :list="societa"
                @hideList="
                  inputFocus = null;
                  resetSocieta;
                "
                @selectedSocieta="
                  setSocieta($event);
                  resetSocieta();
                  inputFocus = null;
                "
              ></ListSocieta>
            </div>
            <div class="col-sm-6 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Persona"
                  name="nome_tesserato"
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  @input="
                    inputFocus = 'persona';
                    getTesserati($event.target.value);
                  "
                  v-model="nomeTesserato"
                />
                <input
                  name="id_tesserato"
                  type="hidden"
                  class="form-control"
                  v-model="id_tesserato"
                /><svg
                  @click="setTesserato(null)"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListTesserati
                v-if="inputFocus === 'persona'"
                :list="tesserati"
                @hideList="
                  inputFocus = null;
                  resetTesserati;
                "
                @selectedTesserato="
                  setTesserato($event);
                  resetTesserati();
                  inputFocus = null;
                "
              ></ListTesserati>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-sm-3 filter">
              <Datepicker
                v-model="dataInsDa"
                placeholder="Data da"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                inputClassName="h-28"
                @update:modelValue="setDataInsDa($event)"
                autoApply
              />
            </div>
            <div class="col-sm-3">
              <Datepicker
                v-model="dataInsA"
                placeholder="Data a"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                inputClassName="h-28"
                @update:modelValue="setDataInsA($event)"
                autoApply
              />
            </div>
            <div class="col-sm-3 filter">
              <Datepicker
                v-model="dataApprDa"
                placeholder="Data approvazione da"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                inputClassName="h-28"
                @update:modelValue="setDataApprDa($event)"
                autoApply
              />
            </div>
            <div class="col-sm-3">
              <Datepicker
                v-model="dataApprA"
                placeholder="Data approvazione a"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                inputClassName="h-28"
                @update:modelValue="setDataApprA($event)"
                autoApply
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3 filter">
              <input
                placeholder="Numero"
                name="numero"
                type="text"
                class="form-control"
                @input="setNumero"
                :value="numero"
              />
            </div>
            <div class="col-sm-3 filter">
              <SelectInput
                placeholder="Approvato"
                :options="selectApprovato"
                :value="approvato"
                name="select_approvato"
                @changeSelect="setApprovato"
              />
            </div>
            <div class="col-sm-3 filter">
              <SelectInput
                :options="mesi"
                name="mese_approvazione"
                placeholder="Mese approvazione"
                :value="meseApprovazione"
                @changeSelect="setMeseApprovazione"
              />
            </div>
          </div>
          <div class="text-center mt-5">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600"
              @click="$emit('getEconomatoList')"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click.prevent="$emit('resetFilters')"
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import Datepicker from "vue3-date-time-picker";
import { useFormatDateYMD, format } from "@/composables/formatDate";
import ListTesserati from "@/components/components-fit/utility/ListTesserati.vue";
import ListSocieta from "@/components/components-fit/utility/ListSocieta.vue";

export default defineComponent({
  name: "filtri-pagamenti-economato",
  emits: ["resetFilters", "getEconomatoList"],
  props: {
    resetted: {
      type: Boolean,
    },
  },
  components: {
    Datepicker,
    SelectInput,
    ListTesserati,
    ListSocieta,
  },

  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const id_tesserato = ref(null);
    const getTesserati = (string) => {
      store.commit("setNomeTesseratoPagamenti", string);
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const resetTesserati = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const id_societa = ref(null);
    const getSocieta = (string) => {
      store.commit("setNomeSocietaPagamenti", string);
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };

    const stagioneEconomato = computed(() => store.getters.stagioneSelected);
    watch(stagioneEconomato, () => {
      emit("getEconomatoList");
    });

    watch(
      () => props.resetted,
      (value) => {
        if (value) {
          id_societa.value = null;
          id_tesserato.value = null;
        }
      }
    );

    const idComitatoRegionale = computed(
      () => store.getters.idComitatoRegionalePagamenti
    );
    const tipoPagamento = computed(() => store.getters.tipoPagamentoPagamenti);
    const tipoCredDeb = computed(() => store.getters.tipoCredDebPagamenti);
    const idSocieta = computed(() => store.getters.idSocietaPagamenti);
    const idTesserato = computed(() => store.getters.idTesseratoPagamenti);
    const dataInsDa = computed(() => store.getters.dataInsDaPagamenti);
    const dataInsA = computed(() => store.getters.dataInsAPagamenti);
    const dataApprDa = computed(() => store.getters.dataApprDaPagamenti);
    const dataApprA = computed(() => store.getters.dataApprAPagamenti);
    const numero = computed(() => store.getters.numeroPagamenti);

    const approvato = computed(() => store.getters.approvatoPagamenti);
    const meseApprovazione = computed(
      () => store.getters.meseApprovazionePagamenti
    );

    const comitati = computed(() => store.getters.getStateFromName("comitati"));
    const tipiPagamento = computed(() =>
      store.getters.getStateFromName("pagamento_tipo")
    );
    const nomeSocieta = computed(() => store.getters.nomeSocietaPagamenti);
    const nomeTesserato = computed(() => store.getters.nomeTesseratoPagamenti);

    const setComitato = (event) => {
      store.commit("setIdComitatoRegionalePagamenti", event);
    };
    const setTipoPagamento = (event) => {
      store.commit("setTipoPagamentoPagamenti", event);
    };
    const setTipoCredDeb = (event) => {
      store.commit("setTipoCredDebPagamenti", event);
    };
    const setSocieta = (event) => {
      store.commit("setSocietaPagamenti", event);
    };
    const setTesserato = (event) => {
      store.commit("setTesseratoPagamenti", event);
    };
    const setDataInsDa = (event) => {
      store.commit("setDataInsDaPagamenti", event);
    };
    const setDataInsA = (event) => {
      store.commit("setDataInsAPagamenti", event);
    };
    const setDataApprDa = (event) => {
      store.commit("setDataApprDaPagamenti", event);
    };
    const setDataApprA = (event) => {
      store.commit("setDataApprAPagamenti", event);
    };
    const setNumero = (event) => {
      store.commit("setNumeroPagamenti", event.target.value);
    };
    const setApprovato = (event) => {
      store.commit("setApprovatoPagamenti", event);
    };
    const setMeseApprovazione = (event) => {
      store.commit("setMeseApprovazionePagamenti", event);
    };

    // verifico se le lookup esistono, altrimanti le carico da api
    const keysComitati = ref("c|d|su|ip|sti");
    if (!comitati.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keysComitati.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const keysPagamentiTipo = ref("pt");
    if (!tipiPagamento.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keysPagamentiTipo.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const inputFocus = ref(null);

    return {
      isActive: false,
      id_tesserato,
      getTesserati,
      getSocieta,
      id_societa,
      tesserati: computed(() => store.getters.personeSocieta),
      societa: computed(() => store.getters.societaNomeCodAff),
      resetTesserati,
      resetSocieta,
      nomeSocieta,
      nomeTesserato,
      inputFocus,

      idComitatoRegionale,
      tipoPagamento,
      tipoCredDeb,
      idSocieta,
      idTesserato,
      dataInsDa,
      dataInsA,
      dataApprDa,
      dataApprA,
      numero,
      approvato,
      meseApprovazione,

      setComitato,
      setTipoPagamento,
      setTipoCredDeb,
      setTesserato,
      setSocieta,
      setDataInsDa,
      setDataInsA,
      setDataApprDa,
      setDataApprA,
      setNumero,
      setApprovato,
      setMeseApprovazione,
      comitati,
      tipi_qualifiche: [
        { id: "1", label: "In approvazione" },
        { id: "2", label: "Validata" },
      ],
      mesi: [
        { id: "1", label: "Gennaio" },
        { id: "2", label: "Febbraio" },
        { id: "3", label: "Marzo" },
        { id: "4", label: "Aprile" },
        { id: "5", label: "Maggio" },
        { id: "6", label: "Giugno" },
        { id: "7", label: "Luglio" },
        { id: "8", label: "Agosto" },
        { id: "9", label: "Settembre" },
        { id: "10", label: "Ottobre" },
        { id: "11", label: "Novembre" },
        { id: "12", label: "Dicembre" },
      ],
      tipiPagamento,
      tipiCredDeb: [
        { id: "S", Label: "Societa" },
        { id: "P", Label: "Persona" },
      ],
      selectApprovato: [
        { id: true, Label: "Si" },
        { id: false, Label: "No" },
      ],
      useFormatDateYMD,
      format,

      loaded: computed(() =>
        store.getters.getStateFromName("loadedeconomato_pagamenti_list")
      ),
    };
  },
});
</script>

<style></style>
